<template>
    <div v-if="show" class="topbar">
        <div class="topbar__column topbar__column--statistics">
            <span v-if="showStatistics">{{ user_limits.impressions }} / {{ user.account_plan.monthly_total_impressions
                }} Gameplays</span>
            <div v-if="showStatistics" class="topbar__progress-bar">
                <div :style="{ width: `${progress}%` }" class="topbar__progress-percentage">{{ `${progress}%` }}</div>
            </div>
        </div>

        <div class="topbar__column __options topbar__column--actions">
            <div class="dropdown">
                <div class="dropdown__button --radio" id="dropdownMenuButton" data-toggle="dropdown">
                    <i class="pi pi-ellipsis-v"></i>
                </div>

                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div class="dropdown__item-container">
                        <router-link v-if="showSuscription" :to="{ name: 'Subscription' }" @click="activePage = 'S'"
                            class="dropdown__simple-item">
                            <SubscriptionIcon />
                            <h5 :style="{ color: activePage === 'S' ? 'black' : 'var(--text-color-light)' }">
                                Subscription
                            </h5>
                        </router-link>

                        <router-link v-if="showTeamMates" :to="{ name: 'TeamMates' }" class="dropdown__simple-item"
                            @click="activePage = 'T'">
                            <TeamMatesIcon />
                            <h5 :style="{ color: activePage === 'T' ? 'black' : 'var(--text-color-light)' }">
                                Teams
                            </h5>
                        </router-link>

                        <router-link v-if="showIntegrations" :to="{ name: 'Integrations' }" @click="activePage = 'I'"
                            class="dropdown__simple-item">
                            <IntegrationsIcon />
                            <h5 :style="{ color: activePage === 'I' ? 'black' : 'var(--text-color-light)' }">
                                Integrations
                            </h5>
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="dropdown">
                <div class="dropdown__button" id="dropdownAccountButton" data-toggle="dropdown">
                    <div class="profile-info">
                        <ProfileIcon />
                        <div>
                            <div class="profile-info__name" v-if="user.current_account">
                                {{ user.current_account.business_name }}
                            </div>
                            <div class="profile-info__email">{{ user.email }}</div>
                        </div>
                    </div>

                    <svg @click="activeDropdown = !activeDropdown" style="cursor: pointer;" width="18" height="18"
                        viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="black"
                            d="M7 3.46008L4.55157 5.8327C4.43647 5.94423 4.28998 6 4.11211 6C3.93423 6 3.78774 5.94423 3.67265 5.8327C3.55755 5.72117 3.5 5.57921 3.5 5.40684C3.5 5.23447 3.55755 5.09252 3.67265 4.98099L6.56054 2.18251C6.6861 2.06084 6.83259 2 7 2C7.16741 2 7.3139 2.06084 7.43946 2.18251L10.3274 4.98099C10.4425 5.09252 10.5 5.23447 10.5 5.40684C10.5 5.57921 10.4425 5.72117 10.3274 5.8327C10.2123 5.94423 10.0658 6 9.88789 6C9.71001 6 9.56353 5.94423 9.44843 5.8327L7 3.46008Z" />
                        <path fill="black"
                            d="M7 10.5399L9.44843 8.1673C9.56353 8.05577 9.71002 8 9.88789 8C10.0658 8 10.2123 8.05577 10.3274 8.1673C10.4425 8.27884 10.5 8.42079 10.5 8.59316C10.5 8.76553 10.4425 8.90748 10.3274 9.01901L7.43946 11.8175C7.3139 11.9392 7.16741 12 7 12C6.83259 12 6.6861 11.9392 6.56054 11.8175L3.67265 9.01901C3.55755 8.90748 3.5 8.76553 3.5 8.59316C3.5 8.42079 3.55755 8.27883 3.67265 8.1673C3.78774 8.05577 3.93423 8 4.11211 8C4.28999 8 4.43647 8.05577 4.55157 8.1673L7 10.5399Z" />
                    </svg>
                </div>

                <div class="dropdown-menu" aria-labelledby="dropdownAccountButton">
                    <div class="dropdown__item-container" style="padding: 0px 12px">
                        <div v-if="user.current_account" class="dropdown__item">
                            <div>
                                <ProfileIcon />
                                <span>{{ user.current_account.business_name }}</span>
                            </div>
                            <span class="indicator active"></span>
                        </div>

                        <div class="dropdown__item" v-for="(account, index) in user.related_accounts" :key="index"
                            @click="changeAccount(account.public_id)">
                            <div>
                                <ProfileIcon :active="false" />
                                <span>{{ account.business_name }}</span>
                            </div>
                            <span class="indicator"></span>
                        </div>

                        <router-link class="dropdown__button mt-2" :to="{ name: 'AddAccount' }" v-if="(user.type == 'account' || user.type == 'child_account') &&
                            ((Object.keys(user.related_accounts).length === 0 && user.plan.sub_accounts > 1) ||
                                user.related_accounts.length < user.plan.sub_accounts)">
                            <i class="pi pi-plus"></i>
                            <span>Add Account</span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '@/api/http'
import { ProfileIcon, SubscriptionIcon, TeamMatesIcon, IntegrationsIcon } from '@/plugins/icons';

export default {
    name: 'TopBar',
    components: {
        ProfileIcon,
        SubscriptionIcon,
        TeamMatesIcon,
        IntegrationsIcon
    },
    computed: {
        show() { return this.user.public_id ? true : false },
        showStatistics() { return (this.user_limits && this.user && ((this.user.type === 'user' && typeof this.access_modules['account_managment'] !== 'undefined') || this.user.type === 'account')) },
        showSuscription() { return this.user.type === 'account' },
        showTeamMates() { return ((typeof this.access_modules['teams'] !== 'undefined' && this.user.type === 'user') || this.user.type === 'account') },
        showIntegrations() { return (typeof this.access_modules['integrations'] !== 'undefined' || this.user.type === 'account') },
        access_modules() { return this.$store.getters['User/access_modules'] },
        user() { return this.$store.getters['User/user'] },
        user_limits() { return this.$store.getters['User/user_limits'] },
        progress() { return (this.user_limits.impressions * 100) / this.user.account_plan.monthly_total_impressions }
    },
    data() {
        return {
            activeDropdown: false,
            activePage: null
        }
    },
    methods: {
        changeAccount(publicUrl) {
            API.post('change-login/' + publicUrl).then((response) => {
                if (response.user) {
                    API.setUser(response.user)
                    this.$store.commit('Message/SET_MESSAGE', {
                        message: response.message,
                        type: 'success'
                    })

                    if (response.user.type === 'user') {
                        this.$store.commit('User/SET_ACCESS_MODULES', response.access_modules)
                    }
                    if (response.limits) {
                        this.$store.commit('User/SET_ACCOUNT_LIMITS', response.limits)
                    }

                    const accessModules = this.$store.getters['User/access_modules']
                    if (
                        typeof accessModules.full !== 'undefined' ||
                        typeof accessModules.games !== 'undefined'
                    ) {
                        this.$router.push({ name: 'Dashboard' })
                    } else {
                        for (const key of Object.keys(accessModules)) {
                            if (typeof this.GamesHelp.routes[key] !== 'undefined') {
                                this.$router.push({ name: this.GamesHelp.routes[key] })
                                break
                            }
                        }
                    }

                    if (this.$route.name !== 'Dashboard') {
                        this.$router.push({ name: 'Dashboard' })
                    }
                }
            })
        }
    }
}
</script>