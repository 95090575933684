<template>
    <svg width="40" height="40" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" :fill="active ? 'url(#paint0_linear_985_47)' : '#F1F1F1'" />
        <g :clip-path="active ? 'url(#clip0_985_47)' : 'url(#clip0_988_6661)'">
            <path v-if="active"
                d="M10.62 24.12C11.64 23.34 12.78 22.7252 14.04 22.2756C15.3 21.826 16.62 21.6008 18 21.6C19.38 21.5992 20.7 21.8244 21.96 22.2756C23.22 22.7268 24.36 23.3416 25.38 24.12C26.08 23.3 26.6252 22.37 27.0156 21.33C27.406 20.29 27.6008 19.18 27.6 18C27.6 15.34 26.6652 13.0748 24.7956 11.2044C22.926 9.334 20.6608 8.3992 18 8.4C15.3392 8.4008 13.074 9.336 11.2044 11.2056C9.3348 13.0752 8.4 15.34 8.4 18C8.4 19.18 8.5952 20.29 8.9856 21.33C9.376 22.37 9.9208 23.3 10.62 24.12ZM18 19.2C16.82 19.2 15.8248 18.7952 15.0144 17.9856C14.204 17.176 13.7992 16.1808 13.8 15C13.8008 13.8192 14.206 12.824 15.0156 12.0144C15.8252 11.2048 16.82 10.8 18 10.8C19.18 10.8 20.1752 11.2052 20.9856 12.0156C21.796 12.826 22.2008 13.8208 22.2 15C22.1992 16.1792 21.7944 17.1744 20.9856 17.9856C20.1768 18.7968 19.1816 19.2016 18 19.2ZM18 30C16.34 30 14.78 29.6848 13.32 29.0544C11.86 28.424 10.59 27.5692 9.51 26.49C8.43 25.4108 7.5752 24.1408 6.9456 22.68C6.316 21.2192 6.0008 19.6592 6 18C5.9992 16.3408 6.3144 14.7808 6.9456 13.32C7.5768 11.8592 8.4316 10.5892 9.51 9.51C10.5884 8.4308 11.8584 7.576 13.32 6.9456C14.7816 6.3152 16.3416 6 18 6C19.6584 6 21.2184 6.3152 22.68 6.9456C24.1416 7.576 25.4116 8.4308 26.49 9.51C27.5684 10.5892 28.4236 11.8592 29.0556 13.32C29.6876 14.7808 30.0024 16.3408 30 18C29.9976 19.6592 29.6824 21.2192 29.0544 22.68C28.4264 24.1408 27.5716 25.4108 26.49 26.49C25.4084 27.5692 24.1384 28.4244 22.68 29.0556C21.2216 29.6868 19.6616 30.0016 18 30Z"
                fill="white" />
            <path v-else
                d="M10.62 24.12C11.64 23.34 12.78 22.7252 14.04 22.2756C15.3 21.826 16.62 21.6008 18 21.6C19.38 21.5992 20.7 21.8244 21.96 22.2756C23.22 22.7268 24.36 23.3416 25.38 24.12C26.08 23.3 26.6252 22.37 27.0156 21.33C27.406 20.29 27.6008 19.18 27.6 18C27.6 15.34 26.6652 13.0748 24.7956 11.2044C22.926 9.334 20.6608 8.3992 18 8.4C15.3392 8.4008 13.074 9.336 11.2044 11.2056C9.3348 13.0752 8.4 15.34 8.4 18C8.4 19.18 8.5952 20.29 8.9856 21.33C9.376 22.37 9.9208 23.3 10.62 24.12ZM18 19.2C16.82 19.2 15.8248 18.7952 15.0144 17.9856C14.204 17.176 13.7992 16.1808 13.8 15C13.8008 13.8192 14.206 12.824 15.0156 12.0144C15.8252 11.2048 16.82 10.8 18 10.8C19.18 10.8 20.1752 11.2052 20.9856 12.0156C21.796 12.826 22.2008 13.8208 22.2 15C22.1992 16.1792 21.7944 17.1744 20.9856 17.9856C20.1768 18.7968 19.1816 19.2016 18 19.2ZM18 30C16.34 30 14.78 29.6848 13.32 29.0544C11.86 28.424 10.59 27.5692 9.51 26.49C8.43 25.4108 7.5752 24.1408 6.9456 22.68C6.316 21.2192 6.0008 19.6592 6 18C5.9992 16.3408 6.3144 14.7808 6.9456 13.32C7.5768 11.8592 8.4316 10.5892 9.51 9.51C10.5884 8.4308 11.8584 7.576 13.32 6.9456C14.7816 6.3152 16.3416 6 18 6C19.6584 6 21.2184 6.3152 22.68 6.9456C24.1416 7.576 25.4116 8.4308 26.49 9.51C27.5684 10.5892 28.4236 11.8592 29.0556 13.32C29.6876 14.7808 30.0024 16.3408 30 18C29.9976 19.6592 29.6824 21.2192 29.0544 22.68C28.4264 24.1408 27.5716 25.4108 26.49 26.49C25.4084 27.5692 24.1384 28.4244 22.68 29.0556C21.2216 29.6868 19.6616 30.0016 18 30Z"
                fill="black" />
        </g>
        <defs v-if="active">
            <linearGradient id="paint0_linear_985_47" x1="-3.62836e-07" y1="17.8896" x2="36" y2="17.8896"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#4A3494" />
                <stop offset="0.0549206" stop-color="#4F3795" />
                <stop offset="0.0924392" stop-color="#583C98" />
                <stop offset="0.2" stop-color="#6A479C" />
                <stop offset="0.364138" stop-color="#81499D" />
                <stop offset="0.450668" stop-color="#94469B" />
                <stop offset="0.5" stop-color="#A14299" />
                <stop offset="0.647401" stop-color="#CC3293" />
                <stop offset="0.780331" stop-color="#EE236C" />
                <stop offset="0.818455" stop-color="#EF4B5E" />
                <stop offset="0.859048" stop-color="#F2624E" />
                <stop offset="0.915121" stop-color="#F57E27" />
                <stop offset="0.953787" stop-color="#F7932D" />
                <stop offset="1" stop-color="#FBAE48" />
            </linearGradient>
            <clipPath id="clip0_985_47">
                <rect width="24" height="24" fill="white" transform="translate(6 6)" />
            </clipPath>
        </defs>
        <defs v-else>
            <clipPath id="clip0_988_6661">
                <rect width="24" height="24" fill="white" transform="translate(6 6)" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>

export default {
    name: 'ProfileIcon',
    props: {
        active: {
            type: Boolean,
            default: true
        }
    }
}
</script>