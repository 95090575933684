<template>
    <nav ref="sidebar" class="side-menu">
        <div class="admin-navbar">
            <div>
                <!-- Logo -->
                <div class="sidebar-logo">
                    <div class="site-logo text-center my-4">
                        <img :src="logo" alt="Klujo">
                    </div>
                </div>

                <!-- Menu -->
                <div class="sidebar__menu">
                    <SideOptions :structured="sideStructured" :compressed="!expand" />
                </div>
            </div>

            <!-- Footer -->
            <div v-if="showLoginElements" class="sidebar__footer">
                <LogoutIcon @click="logout" class="popover__item" />
            </div>
        </div>
    </nav>
</template>

<script>
import mobileLogo from '@/assets/mobile-logo.png';
import API from '@/api/http'
import { SideOptions } from '@/plugins/components';
import { Popover, Button } from '@plugins/primevue-components';
import { DashboardIcon, DomainIcon, GameIcon, RewardIcon, TemplateIcon, UserDataIcon, LogoutIcon, SettingsIcon } from '@/plugins/icons';
import { markRaw } from "vue";

export default {
    name: 'SideMenu',
    components: {
        Popover,
        Button,
        DashboardIcon,
        DomainIcon,
        GameIcon,
        RewardIcon,
        TemplateIcon,
        UserDataIcon,
        LogoutIcon,
        SideOptions,
        SettingsIcon
    },
    computed: {
        access_modules() { return this.$store.getters['User/access_modules'] },
        user() { return this.$store.getters['User/user'] },
        user_limits() { return this.$store.getters['User/user_limits'] },
        showLoginElements() {
            return (
                this.user &&
                this.user.account_plan &&
                this.user.account_plan.status === 'paid' &&
                this.$route.name !== 'AddAccount' &&
                this.$route.name !== 'Welcome'
            )
        },
        sideStructured() {
            const structured = [];

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'DA', label: 'Dashboard', action: () => this.$router.push({ name: 'Dashboard' }), icon: markRaw(DashboardIcon) });
            }

            if ((typeof this.access_modules['teams'] != 'undefined' && this.user.type == 'user') || this.user.type == 'account') {
                structured.push({ code: 'DO', label: 'Domains', action: () => this.$router.push({ name: 'Domains' }), icon: markRaw(DomainIcon) });
            }

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'TE', label: 'Templates', action: () => this.$router.push({ name: 'TemplateList' }), icon: markRaw(TemplateIcon) });
            }

            if (typeof this.access_modules['games'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'GA', label: 'Games', action: () => this.$router.push({ name: 'GameList' }), icon: markRaw(GameIcon) });
            }

            if (typeof this.access_modules['rewards'] != 'undefined' || this.user.type == 'account') {
                structured.push({ code: 'RE', label: 'Rewards', action: () => this.$router.push({ name: 'RewardList' }), icon: markRaw(RewardIcon) });
            }

            if (this.user.plan.crm == 1) {
                structured.push({ code: 'UD', label: 'Player Data', action: () => this.$router.push({ name: 'CRMList' }), icon: markRaw(UserDataIcon) });
            }

            return structured;
        }
    },
    data() {
        return {
            mediaBaseURL: import.meta.env.VITE_MEDIA_BASE_URL,
            logo: mobileLogo,
            expand: false
        }
    },
    methods: {
        logout() {
            API.logout().then(() => {
                this.$router.push({ name: 'Login' })
            }).catch(() => this.$router.push({ name: 'Login' }))
        }
    }
}
</script>

<style scoped>
.side-menu {
    width: var(--sidebar-width);
    position: fixed;
    z-index: 1049;
    height: 100vh;
    padding: 10px;
}

.popover {
    display: grid;
    padding: 12px;
    gap: 10px;
    min-width: 240px;
}

.popover__item {
    margin: auto;
    width: 100%;
    color: #989898;
}

.sidebar__footer {
    padding: 30px 20px;
    cursor: pointer;
}

.pointer {
    border: 1px solid #C0C2C6;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}
</style>