<template>
    <div class="option-container" :class="{ 'compress': compressed }">
        <div v-if="level === 1" @click="goBack" class="option" :class="{ 'compress': compressed }">
            <i class="pi pi-chevron-left"></i>
            <h5>Back</h5>
        </div>

        <div v-for="option in (level === 0 ? structured : childrens)" :key="option.code" class="option"
            @click="selectOption(option)" :class="{ 'compress': compressed }">
            <component :is="option.icon" />
            <h5 :class="{ 'active': activeOption === option.code }">{{ option.label }}</h5>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SideOptions',
    props: {
        structured: {
            required: true,
            type: Array
        },
        compressed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeOption: null,
            level: 0,
            childrens: []
        }
    },
    methods: {
        selectOption(option) {
            if (option.childrens) {
                this.level = 1;
                this.childrens = option.childrens;
            } else if (option.action) {
                option.action();
            }
            this.activeOption = option.code;
        },
        goBack() {
            this.level = 0;
            this.childrens = [];
        }
    }
}
</script>

<style scoped>
.option-container {
    padding: 20px 6px;
    display: grid;
    gap: 26px;
}

.option-container .option {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    transition: all 0.2s ease;
    flex-wrap: wrap;
    flex-grow: 1;
    font-size: 16px;
}

.option-container .option:hover {
    color: #000000;
}

.option-container .option .active {
    color: #000000;
}

.option-container .option.compress {
    flex-direction: column;
    justify-content: center;
    gap: 12px;
}

h5 {
    color: var(--text-color-light);
    font-weight: 400;
}

@media (max-width: 900px) {
    h5 {
        font-size: 13px;
    }
}
</style>