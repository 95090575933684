<template>
  <div :class="{ 'container-fluid p-0': true, 'admin-login': showLoginElements }">
    <Loading />
    <Toast />
    <SideMenu v-if="showLoginElements" />
    <div>
      <RouterView v-slot="{ Component }">
        <transition :name="showLoginElements ? 'fade' : 'out-in'">
          <div v-if="showLoginElements" class="main-container" :style="{
            width: showLoginElements ? `calc(100% - var(--sidebar-width))` : '100%',
            marginLeft: showLoginElements ? 'var(--sidebar-width)' : '0px'
          }">
            <TopBar />
            <!--<Header v-if="showLoginElements" />-->

            <div class="container-body mt-4">
              <Component :is="Component" />
            </div>
          </div>
          <div v-else>
            <Component :is="Component" />
          </div>
        </transition>
      </RouterView>
    </div>
    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
    <vue-progress-bar v-html="spinner"></vue-progress-bar>
  </div>
</template>

<script>
import { Toast, Loading, Header, SideMenu, TopBar } from '@plugins/components'

export default {
  name: 'App',
  components: {
    Toast,
    Loading,
    SideMenu,
    // eslint-disable-next-line vue/no-reserved-component-names
    Header,
    TopBar
  },
  computed: {
    spinner() {
      return '<div id="spinner" class="spinner-border text-primary"></div>'
    },
    user() {
      return this.$store.getters['User/user']
    },
    showLoginElements() {
      return (
        this.user &&
        this.user.account_plan &&
        this.user.account_plan.status === 'paid' &&
        this.$route.name !== 'AddAccount' &&
        this.$route.name !== 'Welcome'
      )
    }
  }
}
</script>
<style>
#spinner {
  display: block;
  width: 24px;
  height: 24px;
  position: fixed;
  right: 8px;
  top: 8px;
}
</style>
